import styled from "styled-components";
import ProductList from "./ProductList";
import { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { db } from "../firebase";

const ProductCardStyles = styled.div`
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const ProductCard = ({ deleteProduct, user }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Function to fetch products from the database and set state
    const fetchProducts = () => {
      const productsRef = ref(db, "products");
      // Listen for changes to the products data
      onValue(productsRef, (snapshot) => {
        const productsData = snapshot.val();
        // Check if productsData is not null
        if (productsData) {
          // Convert the products object into an array
          const productsArray = Object.keys(productsData).map((key) => ({
            id: key,
            ...productsData[key],
          }));
          // Set the products state with the fetched data
          setProducts(productsArray);
        } else {
          // If there are no products, set an empty array
          setProducts([]);
        }
      });
    };

    // Call fetchProducts initially to fetch products when the component mounts
    fetchProducts();

    // Cleanup function to unsubscribe from the database listener when the component unmounts
    return () => {
      const productsRef = ref(db, "products");
      onValue(productsRef, () => {}); // Unsubscribe from changes
    };
  }, [deleteProduct]); // Re-run useEffect when deleteProduct changes
  return (
    <ProductCardStyles>
      {products.map((product) => (
        <ProductList
          key={product.id}
          product={product}
          deleteProduct={deleteProduct}
          user= {user}
        />
      ))}
    </ProductCardStyles>
  );
};

export default ProductCard;
