import React from "react";
import styled from "styled-components";
import Button from "./Button";
import PText from "./PText";

const InvStyles = styled.div`
  .loan {
    height: 50vh;
    width: 90vw;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid white;
    background-position: center;
    margin: 0 auto;
  }
  .loan-inner {
    border: 1px solid white;
    height: 40vh;
    width: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  h2 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 768px) {
    .loan {
      width: 85vw;
    }
    h2 {
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }
  }
  @media screen and (max-width: 480px) {
    .loan {
      width: 85vw;
    }
  }
`;

export default function LoanSection() {
  return (
    <InvStyles>
      <div className="loan">
        <div className="loan-inner">
          <h2>LOAN APPLICATION</h2>
          <PText>Apply online, using our online loan application</PText>
          <Button btnText="Apply Online" btnLink="/apply-online"></Button>
        </div>
      </div>
    </InvStyles>
  );
}
