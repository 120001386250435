import React from 'react';
import styled from 'styled-components';
import FooterCol from './FooterCol';
import PText from './PText';

const FooterStyle = styled.div`
  background-color: var(--deep-dark);
  padding-top: 5rem;
  .container {
    display: flex;
    gap: 3rem;
  }
  .footer__col1 {
    flex: 2;
  }
  .footer__col2,
  .footer__col3,
  .footer__col4 {
    flex: 1.5;
  }
  .footer__col1__title {
    font-size: 2.4rem;
    margin-bottom: 1rem;
  }
  .copyright {
    background-color: var(--dark-bg);
    text-align: left;
    padding: 1rem 0;
    margin-top: 5rem;
    .para {
      margin-left: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    .container {
      flex-direction: column;
      gap: 2rem;
    }
    .footer__col1,
    .footer__col2,
    .footer__col3,
    .footer__col4 {
      flex: 1;
    }
  }
  @media screen and (max-width: 480px) {
    .container {
      flex-direction: column;
      gap: 1rem;
    }
    .footer__col1,
    .footer__col2,
    .footer__col3,
    .footer__col4 {
      flex: 1;
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyle>
      <div className="container">
        <div className="footer__col1">
          <h1 className="footer__col1__title">Store Hours</h1>
          <PText>Mon : 10:00 AM - 7:00 PM</PText>
          <PText>Tue : 10:00 AM - 7:00 PM</PText>
          <PText>Wed : 10:00 AM - 7:00 PM</PText>
          <PText>Thu : 10:00 AM - 7:00 PM</PText>
          <PText>Fri : 10:00 AM - 7:00 PM</PText>
          <PText>Sat : 10:00 AM - 7:00 PM</PText>
          <PText>Sun : Closed</PText>
        </div>
        <div className="footer__col2">
          <FooterCol
            heading="Important Links"
            links={[
              {
                title: "Home",
                path: "/",
                type: "Link",
              },
              {
                type: "Link",
                title: "About",
                path: "/about",
              },
              {
                type: "Link",
                title: "Projects",
                path: "/projects",
              },
              {
                type: "Link",
                title: "Contact",
                path: "/contact-us",
              },
            ]}
          />
        </div>
        <div className="footer__col3">
          <FooterCol
            heading="Contact Info"
            links={[
              {
                title: "7052979708",
                path: "tel:7052979708",
              },
              {
                title: "autosales.wad@gmail.com",
                path: "autosales.wad@gmail.com",
              },
              {
                title: "210 Drive in Rd, Sault Ste. Marie, ON P6B 6A9",
                path: "http://google.com/maps",
              },
            ]}
          />
        </div>
        <div className="footer__col4">
          <FooterCol
            heading="Social Links"
            links={[
              {
                title: "Facebook",
                path: "https://www.facebook.com/profile.php?id=61557986364695&mibextid=ZbWKwL",
              },
              {
                title: "Twitter",
                path: "http://twitter.com",
              },
              {
                title: "Instagram",
                path: "http://instagram.com",
              },
            ]}
          />
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <PText>© 2024</PText>
        </div>
      </div>
    </FooterStyle>
  );
}
