import React from "react";
import styled from "styled-components";

const Qstyles = styled.div`
  margin: 0 auto;
  font-size: 2rem;
  input {
    height: 2.5rem;
  }
`;

export default function QInput() {
  return (
    <Qstyles>
      <div className="input">
        <input type="email" name="min-price" placeholder="Make"></input>
        <input type="email" name="max-price" placeholder="Model"></input>
        <input type="email" name="min-price" placeholder="Min Price"></input>
        <input type="email" name="max-price" placeholder="Max Price"></input>
      </div>
    </Qstyles>
  );
}
