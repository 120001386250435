import React from "react";
import QInput from "./QInput";
import Button from "./Button";
import styled from "styled-components";


const QuickStyles = styled.div`
  .quicksearch {
    height: 90vh;
    min-height: 500px;
    width: 90vw;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-image: url("/background.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    border: 1px solid white;
  }

  .quicksearch__heading {
    font-size: 5rem;
    margin-top: -25rem;
    position: relative;

    span {
      display: inline-block;
      width: 100%;
    }
    h2 {
      font-size: 3.5rem;
      margin-bottom: 1rem;
      color: white;
    }
  }
  .quicksearch__img {
    max-width: 900px;
    width: 100%;
    height: 600px;
    margin: 0 auto;
    /* border: 2px solid var(--gray-1); */
  }
  .quicksearch__input {
    margin-top: -25rem;
  }
  @media screen and (max-width: 768px) {
    .quicksearch {
      width: 85vw;
    }
  }
  @media screen and (max-width: 480px) {
    .quicksearch {
      width: 85vw;
    }
  }
`;

export default function QuickSearchSection() {
  return (
    <QuickStyles>
      <div className="quicksearch">
        <div className="container">
          <div className="quicksearch__img"></div>
          <div className="quicksearch__input">
            <h1 className="quicksearch__heading">
              <h2 className="quickname">QUICK SEARCH</h2>
            </h1>
            <QInput />
            <Button btnText="Search" />
          </div>
        </div>
      </div>
    </QuickStyles>
  );
}
