// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue, push } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDQ8x7BoJLL3pvZ7fR0DcLKPyn-5grqGJk",
  authDomain: "car-website-fe661.firebaseapp.com",
  projectId: "car-website-fe661",
  storageBucket: "car-website-fe661.appspot.com",
  messagingSenderId: "443012610379",
  appId: "1:443012610379:web:8d67ec80e5329a882a90ce",
  measurementId: "G-SKD0BXLMXZ",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);
const storage = getStorage(firebaseApp);
const auth = getAuth(firebaseApp);

// const analytics = getAnalytics(app);

export { firebaseApp, db, storage, ref, onValue, push, getStorage, auth };
