import React, { useState, useEffect } from "react";
import { ref, get, update } from "firebase/database";
import { db, storage } from "../firebase";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import styled from "styled-components";

const EditProductStyles = styled.div`
  width: 400px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 5px;
  form {
    display: flex;
    flex-direction: column;
  }
  label {
    font-weight: bold;
    font-size: 12px;
  }
  input[type="text"],
  input[type="number"],
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
  }
  section {
    margin-bottom: 15px;
  }
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  button:hover {
    background-color: #0056b3;
  }
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .remove-image-button {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
  }
  .remove-image-button:hover {
    background-color: #c82333;
  }
  .remove-image-button:focus {
    outline: none;
  }
`;

export default function EditProductForm({ productId, onCancel }) {
  const [product, setProduct] = useState({
    name: "",
    description: "",
    price: "",
    imageUrls: [],
  });

  useEffect(() => {
    const fetchProduct = async () => {
      const productRef = ref(db, `products/${productId}`);
      const snapshot = await get(productRef);
      if (snapshot.exists()) {
        setProduct(snapshot.val());
      }
    };

    fetchProduct();
  }, [productId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateForm = () => {
    return (
      product.name &&
      product.description &&
      product.price &&
      product.imageUrls.length > 0
    );
  };

  const uploadImages = async (e) => {
    const imageFiles = Array.from(e.target.files);
    const urls = await Promise.all(
      imageFiles.map(async (imageFile) => {
        const imageRef = storageRef(storage, `images/${imageFile.name}`);
        await uploadBytes(imageRef, imageFile);
        return getDownloadURL(imageRef);
      })
    );
    setProduct((prevState) => ({
      ...prevState,
      imageUrls: [...prevState.imageUrls, ...urls],
    }));
  };

  const handleRemoveImage = (index) => {
    setProduct((prevState) => ({
      ...prevState,
      imageUrls: prevState.imageUrls.filter((_, i) => i !== index),
    }));
  };

  const updateProduct = async () => {
    if (!validateForm()) {
      alert("Please fill in all fields and upload at least one image.");
      return;
    }

    const productRef = ref(db, `products/${productId}`);
    await update(productRef, product);

    onCancel();
  };

  return (
    <EditProductStyles>
      <h2>Edit Product</h2>
      <form>
        <section>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={product.name}
            onChange={handleInputChange}
          />
        </section>
        <section>
          <label>Description:</label>
          <textarea
            name="description"
            value={product.description}
            onChange={handleInputChange}
            style={{ height: "100px", resize: "vertical" }}
          />
        </section>
        <section>
          <label>Price:</label>
          <input
            type="text"
            name="price"
            value={product.price}
            onChange={handleInputChange}
          />
        </section>
        <section>
          <label>Images:</label>
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            multiple
            onChange={uploadImages}
          />
        </section>

        {product.imageUrls.map((imageUrl, index) => (
          <div key={index}>
            <img
              src={imageUrl}
              alt={`Product ${index}`}
              style={{ maxWidth: "200px" }}
            />
            <button
              type="button"
              className="remove-image-button"
              onClick={() => handleRemoveImage(index)}
            >
              Remove Image
            </button>
          </div>
        ))}

        <button
          type="button"
          disabled={!validateForm()}
          onClick={updateProduct}
        >
          Update Product
        </button>
        <button type="button" onClick={onCancel}>
          Cancel
        </button>
      </form>
    </EditProductStyles>
  );
}

