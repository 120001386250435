import React, { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged
} from "firebase/auth";
import { auth } from "../firebase";
import styled from "styled-components";

const SignInStyles = styled.div`
  height: 90vh;
  min-height: 500px;
  width: 90vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  input {
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .signin-button {
    width: 150px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .google-signin-button {
    padding: 10px;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .signout-button {
    padding: 10px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
`;

export default function SignInForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(userCredential.user);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSignInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      setUser(userCredential.user);
      // If sign in with Google is successful, you can redirect the user to another page or perform other actions
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <SignInStyles>
      <h2>Sign In</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}{" "}
      {/* Display error message if error state is not null */}
      {user ? (
        <div>
          <p>Welcome, {user.email}</p>
          <button className="signout-button" onClick={handleSignOut}>
            Sign Out
          </button>
        </div>
      ) : (
        <div>
          <form onSubmit={handleSignIn}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            <button className="signin-button" type="submit">
              Sign In
            </button>
          </form>
          <button
            className="google-signin-button"
            onClick={handleSignInWithGoogle}
          >
            Sign In with Google
          </button>
        </div>
      )}
    </SignInStyles>
  );
}
