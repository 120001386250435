import React from "react";
import { useState } from "react";
import styled from "styled-components";

const SlideshowContainer = styled.div`
  position: relative;
`;

const SlideshowImage = styled.img`
  width: 100%;
  height: auto;
`;

const PrevButton = styled.button`
  position: absolute;
  top: 8%;
  left: 0;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
`;

const NextButton = styled.button`
  position: absolute;
  top: 8%;
  right: 0;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
`;

const ImageSlideshow = ({ imageUrls }) => {
const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <SlideshowContainer>
      <SlideshowImage
        src={imageUrls[currentIndex]}
        alt={`Slide ${currentIndex + 1}`}
      />
        <PrevButton onClick={goToPrevious}>Prev</PrevButton>
      <NextButton onClick={goToNext}>Next</NextButton>
    </SlideshowContainer>
  );
};

export default ImageSlideshow;
