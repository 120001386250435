import React from "react";
import NavMenu from "./components/NavMenu";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Inventory from "./pages/Inventory";
import CarFinder from "./pages/CarFinder";
import ApplyOnline from "./pages/ApplyOnline";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Footer from "./components/Footer";
import SignInForm from "./components/SignInForm ";
import SignUpForm from "./components/SignUpForm";


function App() {
  return (
    <>
      <Router>
        <NavMenu />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/car-finder" element={<CarFinder />} />
          <Route path="/apply-online" element={<ApplyOnline />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/signin" element={<SignInForm />} />
          <Route path="/signup" element={<SignUpForm />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
