import styled from "styled-components";
import ImageSlideshow from "./ImageSlideshow";

const PrdStyles = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  h3 {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: bold;
  }
  img {
    max-width: 100%;
    margin-bottom: 10px;
    max-height: 350px;
    object-fit: cover;
  }
  .button-list {
    display: flex;
    flex-direction: column;
  }
  button {
    background-color: var(--gray-1);
    color: black;
    font-weight: bold;
    margin: 2px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }
  p {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .description {
    margin-bottom: 10px;
  }
  li {
    list-style-type: disc; /* Use 'disc' for filled bullet or 'circle' for hollow bullet */
    margin-bottom: 5px; /* Add spacing between bullets */
    margin-left: 15px;
    font-size: 12px;
  }
  .delete {
  }
`;

const ProductList = ({ product, deleteProduct, user }) => {
  return (
    <PrdStyles>
      {user && (
        <button className="delete" onClick={() => deleteProduct(product.id)}>
          X
        </button>
      )}
      <p>${product.price} plus Taxes</p>
      {product.imageUrls && <ImageSlideshow imageUrls={product.imageUrls} />}
      <h3>{product.name}</h3>
      <ul className="description">
        {product.description.split(",").map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <section className="button-list">
        <button>VIEW DETAILS</button>
      </section>
    </PrdStyles>
  );
};

export default ProductList;
