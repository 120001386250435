import React from 'react'
import ContactSection from "../components/ContactSection";
import Map from "../components/Map";

export default function ContactUs() {
  return (
    <>
      <ContactSection />
      <Map />
    </>
  );
}