import React from "react";
import QuickSearchSection from "../components/QuickSearchSection";
import InventorySection from "../components/InventorySection";
import LoanSection from "../components/LoanSection";
import ProductSlideshow from "../components/ProductSlideshow";
import { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { db } from "../firebase";




export default function Home() {
const [products, setProducts] = useState([]);

useEffect(() => {
  const dbRef = ref(db, "products");

  onValue(dbRef, (snapshot) => {
    const data = snapshot.val();
    if (data) {
      const productsArray = Object.keys(data).map((key) => ({
        id: key,
        ...data[key],
      }));

      setProducts(productsArray);
    }
  });
}, []);


  return (
    <div>
      <QuickSearchSection />
      <ProductSlideshow products={products} />
      <InventorySection />
      <LoanSection />
    </div>
  );
}
