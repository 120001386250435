import { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import LogoImg from "../assets/images/logoimg.jpeg";
import "@fortawesome/fontawesome-free/css/all.min.css";

const NavMenuStyles = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  background: var(--dark-bg);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 110px;
    margin-inline-end: 10px;
  }
  .menu-icon {
    display: none;
    cursor: pointer;
    color: var(--gary-1);
    font-size: 2rem;
    margin-right: 1rem;
  }
  ul {
    max-width: 1200px;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      border-radius: 8px;
      transition: 0.3s ease background-color;

      &:hover {
        background-color: var(--deep-dark);
      }

      a {
        display: inline-block;
        font-family: "RobotoMono Regular";
        padding: 1rem 2rem;
        font-size: 1.5rem;
        color: var(--gary-1);
        text-decoration: none;
        outline: none;

        &.active {
          color: var(--white);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    .menu-icon {
      display: block;
      margin-right: 5rem;
    }

    img {
      width: 150px;
      margin-inline-start: 5rem;
    }

    ul {
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 150px;
      left: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
      background: var(--dark-bg);
      transition: all 0.3s ease;
      li {
        width: 100%;
        text-align: center;
        &:hover {
          background-color: var(--deep-dark);
        }
      }
    }
  }
  @media screen and (max-width: 480px) {
    img {
      width: 120px;
      margin-inline-start: 3rem;
    }
    .menu-icon {
      display: block;
      margin-right: 3rem;
    }
    ul {
      top: 120px;
    }
  }
`;

const NavMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <NavMenuStyles isOpen={isOpen}>
      <img src={LogoImg} alt="" />
      <div className="menu-icon" onClick={toggleMenu}>
        <i className={isOpen ? "fas fa-times" : "fas fa-bars"}></i>
      </div>
      <ul>
        <li>
          <NavLink to="/" exact>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/inventory">Inventory</NavLink>
        </li>
        {/* <li>
          <NavLink to="/car-finder">Car Finder</NavLink>
        </li>
        <li>
          <NavLink to="/apply-online">Apply Online</NavLink>
        </li>
        <li>
          <NavLink to="/about-us">About Us</NavLink>
        </li> */}
        <li>
          <NavLink to="/contact-us">Contact Us</NavLink>
        </li>
      </ul>
    </NavMenuStyles>
  );
};

export default NavMenu;
