import React, { useState, useEffect } from "react";
import ProductCard from "../components/ProductCard";
import { ref, onValue, remove } from "firebase/database";
import { auth, db } from "../firebase";
import AddProductForm from "../components/AddProductForm";

export default function Inventory() {

  const [user, setUser] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const dbRef = ref(db, "products");

    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const productsArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        setProducts(productsArray);
      }
    });
  }, []);

  // const handleImageChange = (e) => {
  //   const imageFile = e.target.files[0];
  //   setNewProduct({ ...newProduct, image: imageFile });
  // };

  const deleteProduct = (productId) => {

    if (!user) {
      alert("Please sign in to delete products.");
      return;  
    }
    const productRef = ref(db, `products/${productId}`);
    
    remove(productRef);
    // Implement deleteProduct logic here
    // This function will be passed to the ProductList component
    console.log("Deleting product with ID:", productId);
  };

  return (
    <div>
      <ProductCard products={products} deleteProduct={deleteProduct} user={user}/>
      <div>
        <AddProductForm user={user} />
      </div>
    </div>
  );
}
