import React from "react";
import styled from "styled-components";
import Button from "./Button";
import PText from "./PText";

const InvStyles = styled.div`
  .inventory {
    height: 50vh;
    width: 90vw;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid white;
    background-position: center;
    margin: 0 auto;
  }
  .inventory-inner {
    border: 1px solid white;
    height: 40vh;
    width: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  h2 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 768px) {
    .inventory {
      width: 85vw;
    }
    h2 {
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }
    
  }
  @media screen and (max-width: 480px) {
    .inventory {
      width: 85vw;
    }
  }
`;

export default function InventorySection() {
  return (
    <InvStyles>
      <div className="inventory">
        <div className="inventory-inner">
          <h2>INVENTORY</h2>
          <PText>View our large selection of Quality used vehicles.</PText>
          <Button btnText="View Inventory" btnLink="/inventory"></Button>
        </div>
      </div>
    </InvStyles>
  );  
}
